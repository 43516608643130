
import Vue from "vue";
import { IBreakpointInfo, IPage } from "../store/state";
import Page from "../components/Page.vue";
import { incrementPage, decrementPage, setSearching } from "../store/actions";

interface PaginationData {
  breakpointInfo: IBreakpointInfo;
}

interface PaginationMethods {
  incrementPage(): void;
  decrementPage(): void;
}

interface PaginationComputed {
  searching: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  currentBreakpoint: string;
  canIncrement: boolean;
  canDecrement: boolean;
  startIndexDesktop: number;
  startIndexTablet: number;
  startIndexMobile: number;
}

interface PaginationProps {
  breakpointInfo: IBreakpointInfo;
}

export default Vue.extend<
  PaginationData,
  PaginationMethods,
  PaginationComputed,
  PaginationProps
>({
  name: "Pagination",
  props: {
    breakpointInfo: {},
  },
  computed: {
    searching() {
      return this.$store.getters.searching;
    },
    isMobile() {
      return this.breakpointInfo.isMobile;
    },
    isTablet() {
      return this.breakpointInfo.isTablet;
    },
    isDesktop() {
      return this.breakpointInfo.isDesktop;
    },
    currentBreakpoint() {
      if (this.isMobile) {
        return "mobile";
      } else if (this.isTablet) {
        return "tablet";
      } else {
        return "desktop";
      }
    },
    canIncrement() {
      if (this.isMobile) {
        return this.startIndexMobile + 1 < this.$store.getters.totalHits;
      } else if (this.isTablet) {
        return this.startIndexTablet + 2 < this.$store.getters.totalHits;
      } else {
        return this.startIndexDesktop + 4 < this.$store.getters.totalHits;
      }
    },
    canDecrement() {
      if (this.isMobile) {
        return this.startIndexMobile !== 0;
      } else if (this.isTablet) {
        return this.startIndexTablet !== 0;
      } else {
        return this.startIndexDesktop !== 0;
      }
    },
    startIndexDesktop() {
      return this.$store.getters.startIndexDesktop;
    },
    startIndexTablet() {
      return this.$store.getters.startIndexTablet;
    },
    startIndexMobile() {
      return this.$store.getters.startIndexMobile;
    },
  },
  methods: {
    incrementPage() {
      this.$store.dispatch(setSearching(true));
      setTimeout(() => {
        this.$store.dispatch(incrementPage(this.currentBreakpoint));
        this.$store.dispatch(setSearching(false));
      }, 200);
    },
    decrementPage() {
      this.$store.dispatch(setSearching(true));
      setTimeout(() => {
        this.$store.dispatch(decrementPage(this.currentBreakpoint));
        this.$store.dispatch(setSearching(false));
      }, 200);
    },
  },
  components: { Page },
});
