
import Vue from "vue";
import { IBreakpointInfo, IPage } from "../store/state";
import Page from "../components/Page.vue";

interface PageListProps {
  pages: IPage[];
  breakpointInfo: IBreakpointInfo;
}

export default Vue.extend<{}, {}, {}, PageListProps>({
  name: "PageList",
  props: {
    pages: {},
    breakpointInfo: {},
  },
  computed: {
    searching() {
      return this.$store.getters.searching;
    },
    isMobile() {
      return this.breakpointInfo.isMobile;
    },
    isTablet() {
      return this.breakpointInfo.isTablet;
    },
    isDesktop() {
      return this.breakpointInfo.isDesktop;
    },
  },
  components: { Page },
});
