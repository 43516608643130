import { Getter } from "vuex";
import { IState } from "./state";

const title: Getter<IState, IState> = (state) => state.title;
const preamble: Getter<IState, IState> = (state) => state.preamble;
const noPagesText: Getter<IState, IState> = (state) => state.noPagesText;
const searchResult: Getter<IState, IState> = (state) => state.searchResult;
const searchHits: Getter<IState, IState> = (state) =>
  state.searchResult.searchHits;
const totalHits: Getter<IState, IState> = (state) =>
  state.searchResult.searchHits.length;
const archivePage: Getter<IState, IState> = (state) => state.archivePage;
const activePage: Getter<IState, IState> = (state) => state.activePage;
const searching: Getter<IState, IState> = (state) => state.searching;
const allFetchedPages: Getter<IState, IState> = (state) =>
  state.allFetchedPages;
const startIndexDesktop: Getter<IState, IState> = (state) =>
  state.startIndexDesktop;
const startIndexTablet: Getter<IState, IState> = (state) =>
  state.startIndexTablet;
const startIndexMobile: Getter<IState, IState> = (state) =>
  state.startIndexMobile;

export default {
  title,
  preamble,
  noPagesText,
  searchResult,
  searchHits,
  totalHits,
  archivePage,
  activePage,
  searching,
  allFetchedPages,
  startIndexDesktop,
  startIndexTablet,
  startIndexMobile,
};
