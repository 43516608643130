
import Vue from "vue";
import { IPage } from "./store/state";
import PageList from "./components/PageList.vue";
import Pagination from "./components/Pagination.vue";
import { incrementPage, decrementPage, setSearching } from "./store/actions";

// Interfaces for component typechecking!
interface AppData {
  windowWidth: number;
}

interface AppMethods {
  onResize(): void;
  incrementPage(): void;
  decrementPage(): void;
}

interface AppComputed {
  title: string;
  preamble: string;
  pages: IPage[];
  noPagesText: string;
  archivePage: string;
  startIndexDesktop: number;
  startIndexTablet: number;
  startIndexMobile: number;
  currentBreakpoint: string;
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

interface AppProps {}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
  name: "app",
  data() {
    return {
      tabletBreakpoint: 640,
      desktopBreakpoint: 1024,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    title() {
      return this.$store.getters.title;
    },
    preamble() {
      return this.$store.getters.preamble;
    },
    noPagesText() {
      return this.$store.getters.noPagesText;
    },
    pages() {
      let tmpHits = this.$store.getters.searchHits;
      if (this.isMobile) {
        return tmpHits.slice(this.startIndexMobile, this.startIndexMobile + 1);
      } else if (this.isTablet) {
        return tmpHits.slice(this.startIndexTablet, this.startIndexTablet + 2);
      } else {
        console.log(
          "tmpHits.slice(this.startIndexDesktop,this.startIndexDesktop + 4): ",
          tmpHits.slice(this.startIndexDesktop, this.startIndexDesktop + 4)
        );
        return tmpHits.slice(
          this.startIndexDesktop,
          this.startIndexDesktop + 4
        );
      }
    },
    archivePage() {
      return this.$store.getters.archivePage;
    },
    startIndexDesktop() {
      return this.$store.getters.startIndexDesktop;
    },
    startIndexTablet() {
      return this.$store.getters.startIndexTablet;
    },
    startIndexMobile() {
      return this.$store.getters.startIndexMobile;
    },
    currentBreakpoint() {
      if (this.isMobile) {
        return "mobile";
      } else if (this.isTablet) {
        return "tablet";
      } else {
        return "desktop";
      }
    },
    isMobile() {
      if (this.windowWidth <= this.tabletBreakpoint) {
        return true;
      }
      return false;
    },
    isTablet() {
      if (
        this.windowWidth <= this.desktopBreakpoint &&
        this.windowWidth > this.tabletBreakpoint
      ) {
        return true;
      }
      return false;
    },
    isDesktop() {
      if (this.windowWidth > this.desktopBreakpoint) {
        return true;
      }
      return false;
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    incrementPage() {
      this.$store.dispatch(setSearching(true));
      setTimeout(() => {
        this.$store.dispatch(incrementPage(this.currentBreakpoint));
        this.$store.dispatch(setSearching(false));
      }, 200);
    },
    decrementPage() {
      this.$store.dispatch(setSearching(true));
      setTimeout(() => {
        this.$store.dispatch(decrementPage(this.currentBreakpoint));
        this.$store.dispatch(setSearching(false));
      }, 200);
    },
  },
  components: {
    PageList,
    Pagination,
  },
});
