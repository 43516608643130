import { MutationTree, Mutation } from "vuex";
import { IPage, IState } from "./state";
import { ActionPayload } from "./actions";

type MutationHandler<PayloadType> = (
  state: IState,
  payload: ActionPayload<PayloadType>
) => any;

const setStateMutation: MutationHandler<IState> = (state, { payload }) => {
  Object.assign(state, payload);
};
const incrementPageMutation: MutationHandler<string> = (state, { payload }) => {
  if (payload === "mobile") {
    Object.assign(state, { startIndexMobile: state.startIndexMobile + 1 });
  } else if (payload === "tablet") {
    Object.assign(state, { startIndexTablet: state.startIndexTablet + 2 });
  } else {
    Object.assign(state, { startIndexDesktop: state.startIndexDesktop + 4 });
  }
};
const decrementPageMutation: MutationHandler<string> = (state, { payload }) => {
  if (payload === "mobile") {
    Object.assign(state, { startIndexMobile: state.startIndexMobile - 1 });
  } else if (payload === "tablet") {
    Object.assign(state, { startIndexTablet: state.startIndexTablet - 2 });
  } else {
    Object.assign(state, { startIndexDesktop: state.startIndexDesktop - 4 });
  }
};
const setHitsMutation: MutationHandler<IPage[]> = (state, { payload }) => {
  state.searchResult.searchHits = payload;
};
const addHitsMutation: MutationHandler<IPage[]> = (state, { payload }) => {
  state.searchResult.searchHits = [
    ...state.searchResult.searchHits,
    ...payload,
  ];
};

const setSearchingMutation: MutationHandler<boolean> = (state, { payload }) => {
  state.searching = payload;
};

const mutations: MutationTree<IState> = {
  setState: setStateMutation,
  incrementPage: incrementPageMutation,
  decrementPage: decrementPageMutation,
  setHits: setHitsMutation,
  addHits: addHitsMutation,
  setSearching: setSearchingMutation,
};

export default mutations;
