var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"sam-our-initiatives"},[_c('h2',{staticClass:"sam-our-initiatives__title"},[_vm._v(_vm._s(this.title))]),_c('div',{staticClass:"sam-our-initiatives__upper-container"},[_c('p',{staticClass:"sam-our-initiatives__preamble"},[_vm._v(_vm._s(this.preamble))])]),(_vm.pages.length > 0)?_c('div',{staticClass:"sam-our-initiatives__pages-pagination-wrapper"},[_c('Pagination',{attrs:{"breakpointInfo":{
        isMobile: _vm.isMobile,
        isTablet: _vm.isTablet,
        isDesktop: _vm.isDesktop,
      }}}),_c('div',{staticClass:"sam-our-initiatives__pages-container"},[_c('PageList',{attrs:{"pages":_vm.pages,"breakpointInfo":{
          isMobile: _vm.isMobile,
          isTablet: _vm.isTablet,
          isDesktop: _vm.isDesktop,
        }}})],1)],1):_c('div',{staticClass:"sam-our-initiatives__no-pages-container"},[_c('p',{staticClass:"sam-our-initiatives__no-pages-text"},[_vm._v(_vm._s(_vm.noPagesText))])]),_c('div',{staticClass:"sam-our-initiatives__link-wrapper"},[_c('a',{staticClass:"sam-our-initiatives__link",attrs:{"href":this.archivePage}},[_vm._v(" Alla initiativ ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }