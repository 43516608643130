
import Vue from "vue";
import { IPage } from "../store/state";

interface PageProps {
  page: IPage;
  currentBreakpoint: string;
}

export default Vue.extend<{}, {}, {}, PageProps>({
  name: "Page",
  props: {
    page: {},
    currentBreakpoint: {},
  },
  computed: {
    searching() {
      return this.$store.getters.searching;
    },
  },
});
