export interface IState {
  title: string;
  preamble: string;
  noPagesText: string;
  searchResult: ISearchResult;
  archivePage: string;
  activePage: number;
  endpoints: { searchURL: string };
  searching: boolean;
  startIndexDesktop: number;
  startIndexTablet: number;
  startIndexMobile: number;
  allFetchedPages: ISearchResult;
  currentlyShowingPages: IPage[]; //Might not need this
}

export interface IPage {
  title: string;
  preamble: string;
  uri: string;
  imageUri: string;
}
export interface ISearchResult {
  searchHits: IPage[];
  totalPages: number;
}
export interface IBreakpointInfo {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

const deafultState: IState = {
  title: "",
  preamble: "",
  noPagesText: "",
  searchResult: {
    searchHits: [],
    totalPages: 0,
  },
  archivePage: "",
  activePage: 0,
  endpoints: { searchURL: "" },
  searching: false,
  startIndexDesktop: 0,
  startIndexTablet: 0,
  startIndexMobile: 0,
  allFetchedPages: {
    searchHits: [],
    totalPages: 0,
  },
  currentlyShowingPages: [],
};

export default deafultState;
