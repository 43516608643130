import { IState } from "./state";
import { ActionTree, Store, ActionContext } from "vuex";
import * as api from "../api";
import store from ".";

type PayloadType =
  | "initApp"
  | "setState"
  | "incrementPage"
  | "decrementPage"
  | "setSearching";
export type ActionPayload<ActionData> = {
  type: PayloadType;
  payload: ActionData;
};

type ActionHandler<ActionData> = (
  this: Store<IState>,
  injectee: ActionContext<IState, IState>,
  payload: ActionPayload<ActionData>
) => any;
type ActionCreator<ActionData> = (
  data: ActionData
) => ActionPayload<ActionData>;

// Action creators
export const initApp: ActionCreator<IState> = (state) => ({
  type: "initApp",
  payload: state,
});
export const incrementPage: ActionCreator<string> = (payload) => ({
  type: "incrementPage",
  payload: payload,
});
export const decrementPage: ActionCreator<string> = (payload) => ({
  type: "decrementPage",
  payload: payload,
});
export const setSearching: ActionCreator<boolean> = (state) => ({
  type: "setSearching",
  payload: state,
});

// Action handlers
const initAppAction: ActionHandler<IState> = ({ commit }, { payload }) => {
  commit({
    type: "setState",
    payload,
  });
};
const incrementPageAction: ActionHandler<string> = (
  { commit, state },
  { payload }
) => {
  if (payload === "mobile") {
    if (state.startIndexMobile + 1 >= state.searchResult.totalPages) {
      api
        .search(state.endpoints.searchURL, {
          activePage: state.startIndexMobile + 1,
          fromIndex: state.searchResult.searchHits.length,
        })
        .then((res) => {
          commit({
            type: "incrementPage",
            payload: payload,
          });
          commit({
            type: "addHits",
            payload: res.searchHits,
          });
        });
    } else {
      commit({
        type: "incrementPage",
        payload: payload,
      });
    }
  } else if (payload === "tablet") {
    if ((state.startIndexTablet + 1) * 2 >= state.searchResult.totalPages) {
      api
        .search(state.endpoints.searchURL, {
          activePage: state.startIndexTablet + 1,
          fromIndex: state.searchResult.searchHits.length,
        })
        .then((res) => {
          commit({
            type: "incrementPage",
            payload: payload,
          });
          commit({
            type: "addHits",
            payload: res.searchHits,
          });
        });
    } else {
      commit({
        type: "incrementPage",
        payload: payload,
      });
    }
  } else {
    if ((state.startIndexDesktop + 1) * 4 >= state.searchResult.totalPages) {
      api
        .search(state.endpoints.searchURL, {
          activePage: state.startIndexDesktop + 1,
          fromIndex: state.searchResult.searchHits.length,
        })
        .then((res) => {
          commit({
            type: "incrementPage",
            payload: payload,
          });
          commit({
            type: "addHits",
            payload: res.searchHits,
          });
        });
    } else {
      commit({
        type: "incrementPage",
        payload: payload,
      });
    }
  }
};
const decrementPageAction: ActionHandler<string> = (
  { commit, state },
  { payload }
) => {
  commit({
    type: "decrementPage",
    payload: payload,
  });
};
const setSearchingAction: ActionHandler<boolean> = (
  { commit },
  { payload }
) => {
  commit({
    type: "setSearching",
    payload,
  });
};

const actions: ActionTree<IState, IState> = {
  initApp: initAppAction,
  incrementPage: incrementPageAction,
  decrementPage: decrementPageAction,
  setSearching: setSearchingAction,
};

export default actions;
